.contact_container {
  display: flex;
  flex-direction: column;
  padding-top: calc(90px + 2.5%);
  align-items: center;
  max-width: 112rem;
}
.contact_header {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact_container h1 {
  font-size: 50px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 0;
}
.contact_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 500px;
  margin-bottom: 40px;
}
.contact_subtitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
    line-height: 1.4;
}
.contact_content_left {
  height: 100%;
  width: 50%;
}
.contact_content_left_top,
.contact_content_left_bottom,
.contact_content_right_top {
  border-radius: 24px;
  margin: 30px;
  padding: 30px;
  text-align: left;
  align-items: flex-start;
  height: 34%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: solid 1px var(--border-light);
}
.contact_container h2 {
  font-size: 30px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 0;
}
.contact_container h3 {
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  margin: 0;
  line-height: 1.4;
}
.contact_content_h3 {
  margin-top: 10px;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 100%;
  width: 100%;
}
.gmap_canvas {
	border-radius: 12px;
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
  border: 1px solid var(--border-light) !important;
}
.gmap_canvas iframe{
	  width: 100%;
  border-radius: 12px;
  height: 100%;
}
.contact_content_right_top{
  margin-left: 0;
	height: 86%;
}
.contact_content_right{
	width: 50%;
}
@media screen and (max-width: 1050px) {
.contact_content{
  flex-direction: column !important;
  height: auto !important;
  width: 100%;
}
.contact_content_right_top{
  margin-left: 30px;
  margin-top: 0;
  height: 300px;
}
.contact_content_left, .contact_content_right{
  width: 100%;
}
.contact_header{
  margin-left:  30px;
  margin-right: 30px;
}
}
@media screen and (max-width: 500px) {
  .contact_header h1{
    font-size: 40px;
  }
  
}