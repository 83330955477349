.error{
	position: fixed;
	top: 20px;
    right: 20px;
	background: #c52b2c;
	padding: 20px;
	border-radius: 12px;
	color: #fff;
}
.error-message-header{
	margin: 0;
	font-weight: 800;
	font-size: 20px;
}
.error-message{
	margin-top: 10px;
}
.error-message p{
	
	margin: 0;
	font-weight: 600;
}