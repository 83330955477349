.bottom-info {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f6f8fa;
  max-width: 112rem;
  margin-top: 1rem;
  height: auto;
  margin: 1rem auto auto auto;
}
.bottom-info_content {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f6f8fa;
  padding: 20px 0;
  flex-wrap: wrap;
}
.bottom-info_card {
  justify-content: space-around;
  z-index: 2;
  border-radius: 24px;
  border: 1px solid var(--border-light);
  display: flex;
  flex-direction: column;
  width: calc(25% - 83px);
  background-color: #fff;
  margin: 20px;
  padding: 20px;
}
.bottom-info_card-image_container {
  background: linear-gradient(315deg, #f15a24 0%, #c52b2c 100%);
  width: 70px;
  height: 70px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.532);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-info_image {
  margin: 10px;
}
.bottom-info_card h4 {
  font-size: 2rem;
  font-weight: 800;
  color: var(--text-color);
  margin-top: 20px;
  margin-bottom: 15px;
}
.bottom-info_text {
  margin-bottom: 25px;
  margin-top: 0;
  background-color: #fff;
}
@media screen and (max-width: 1080px) {
  .bottom-info_card {
    width: calc(50% - 82px);
  }
}
@media screen and (max-width: 768px) {
  .bottom-info_card {
    width: calc(100% - 82px);
  }
}
