.mascot-container {
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 1000;
  height: 0px;
}
.mozart {
  height: 200px;
  z-index: 1 !important;
}
.mozart-hand {
  position: absolute;
  height: 100px !important;
  right: -110px;
  top: 130px;
  z-index: -1 !important;
  transform-origin: left top;
}
.mozart-dialog-container {
  box-sizing: border-box;
  position: absolute;
  background: #f15a24;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  right: 170px;
  border-radius: 12px;
  top: -20px;
  height: 160px;
  width: 250px;
  padding: 15px;
}
.mozart-dialog-container-logged-in {
  box-sizing: border-box;
  position: absolute;
  background: #f15a24;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  right: 170px;
  border-radius: 12px;
  top: -20px;
  height: auto;
  width: 250px;
  padding: 15px;
}
.header-mozart-dialog {
  font-size: 14px;
  font-weight: 800;
  margin: 0;
}
.mozart-dialog-content {
  display: flex;
  flex-direction: column;
}
.mozart-dialog-content button {
  font-size: 14px;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  height: 30px;
  font-family: "rubrik-new";
  font-weight: 900;
}
.mozart-dialog-content button:hover{
  cursor: pointer;
}
.mozart-dialog-content .login {
  margin: 10px 0 5px;
  background-color: #f15a24;
  border: 2px solid #fff;
  color: #fff;
}
.log-out-button{
  margin-bottom: 0 !important; 
}
@media screen and (max-width: 768px) {
.mascot-container{
  transform: scale(0.8);
}
.mozart-dialog-container{
  transform: scale(1.2) !important;
}
}