.apps-dots {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  bottom: 0;
  right: 0;
  position: fixed;
  width: 56px;
  height: 56px;
  z-index: 995;
  background: linear-gradient(180deg, #f15a24 0%, #c52b2c 100%);
  border-top-left-radius: 12px;
  cursor: pointer ;
}
.small-app-dots-container-left,
.small-app-dots-container-middle,
.small-app-dots-container-right {
  display: flex;
  flex-direction: column;
  position: absolute;
}
.small-app-dots-container-middle {
  left: 50%;
  transform: translateX(-50%);
}
.small-app-dots-container-right {
  left: 39px;
}
.apps-dot-middle,
.apps-dot-right,
.apps-dot-left {
  margin: 3px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: white;
}
.small-dots-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 36px;
  height: 36px;
  padding: 10px;
}
/*loading*/

.loading-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
}
.loading-screen img{
  width: 20rem;
}
/*menu*/
.menu-icons-container{
width: 56px;
display: block;
}
.menu-app-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  width: 56px;
  height: 56px;
  border-radius: 12px;  
box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
background-color: #f6f8fa;
}
.menu-app-container img{
  width: 80%;
  height: 80%;
}
.menu-app-container:first-child, .menu-app-container:last-child{
  margin: 0;
}
.menu-app-container-icons{
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 4px;
  width: 48px !important;
  height: 48px !important;
}
.item-apps-menu-icon{
  border-radius: 8px;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #393939;
}
.menu-icon-users{
  width: 80%;
}
.lf-player-container{
  width: 100%;
  height: 100%;
}
#lottie{
  width: 100%;
  height: 100%;
}
#lottie svg{
  transform: translate3d(0px, 2px, 0px) !important;
  top:0
}
.menu-app-container::after{
  content: attr(iconname);
  position: absolute;
  height: 22px;
  right: 65px;
  border-radius:12px;
  font-weight: 700;
  background-color: #393939;
  color: white;
  padding: 8px;
  font-size: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
}

.menu-app-container:hover::after{ 
  visibility: visible;
  opacity: 1;
}
.notes{
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notes img{
  width: 65%;
  height: 65%;
}