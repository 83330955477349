.register-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.register-container h1 {
  text-align: center;
  font-size: 40px;
  margin-top: 20px;
  font-weight: 800;
  color: var(--text);
  margin-bottom: 20px;
}
.register-box {
  box-sizing: border-box;
  width: 410px;
  background: #fff;
  padding: 30px;
  border-radius: 24px;
  overflow: hidden !important;
}
.register-box form input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 0 10px;
  position: relative !important;
  border: 1px solid var(--border-light);
  font-family: "rubrik-new";
  font-weight: 800;
}
.register-box form input::after {
  src: attr(data-icon);
}
.register-box form input::placeholder {
  color: #757575 !important;
  font-family: "rubrik-new";
  font-weight: 600;
}
.register-box form button,
.registration-success button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-weight: 900;
  cursor: pointer;
  background-color: #f15a24;
  color: #fff;
  font-family: "rubrik-new";
  font-size: 16px;
}
.register-form-input {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.small-registration-image {
  position: absolute;
  height: 20px;
  margin: 5px;
  padding: 5px;
  background: #fae3e3;
  border-radius: 5px;
}
.register-form-have-account p {
  margin: 0;
  color: var(--text);
}
.register-form-have-account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
    flex-direction: column;
}
.register-form-have-account span {
  color: #f15a24;
  font-family: "rubrik-new";
  font-weight: 900;
  cursor: pointer;
}
.register-form-have-account a:hover {
  text-decoration: underline !important;
}
.register-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-small-registration {
  height: 65px;
}
.register-back {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.registration-success p {
  color: var(--text);
}
.registration-success .lf-player-container {
  height: 200px;
}
.registration-success h1 {
  margin-bottom: 10px;
  font-size: 30px;
}
.registration-success p {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  text-align: center;
}
.registration-success button {
  margin-top: 20px;
}
.social-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-light);
  position: relative;
}
.email-login-container{
    padding-bottom: 30px;
    display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
    border-bottom: 1px solid var(--border-light);
}
.social-login-container::after, .email-login-container:after {
  content: "alebo";
  padding: 0 10px;
  position: absolute;
  text-align: center;
  bottom: -10px;
  background: #fff;
}
.social-login-container a,
.email-login-button a, .regstration-mail {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: var(--border-light) 1px solid;
  border-radius: 10px;
  padding: 10px;
  height: 40px;
}
.social-login-container a:first-of-type {
  margin-bottom: 10px;
}
.social-login-container img, .email-login-button img {
  margin-right: 20px;
}
.socal-login {
  height: 20px;
}
.social-login-container p,
.email-login-button p {
  font-size: 14px;
  font-weight: 700;
  color: var(--text);
}
.email-login-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 571px) {
  .register-box {
    box-sizing: border-box;
    overflow: scroll;
  }
  .registration-success .lf-player-container {
    height: auto;
  }
}
