.login-form-input-image {
	display: flex;
	align-items: center;
  position: absolute;
  height: 20px;
  margin: 5px;
  padding: 5px;
  background: #fae3e3;
  border-radius: 5px;
  width: auto;
  overflow: hidden;
}
.login-form-input-image img {
	  height: 20px;
}
.login-form-input-image img[alt="mail"]{
	margin-right: 5px;
}
.provider-redirect{
  font-size: 30px;
  font-weight: 900;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}