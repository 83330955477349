.composer-card-container {
  height: 270px;
  border: solid 3px #393939;
  border-radius: 24px;
  min-width: 200px;
  position: relative;
  width: 20%;
  margin: calc(2.5% - 3px);
}
.composer-card {
  justify-content: center;
  display: flex;
}
.composer-card-name {
  position: absolute;
  width: calc(100% - 10px);
  bottom: 5px;
  height: 30%;
  border-radius: 0 0 18px 18px;
  background: linear-gradient(#00000000 0%, #000 90%);
  padding: 15px;
  box-sizing: border-box;
  font-weight: 600;
}
.composer-card p {
  font-size: 20px;
  color: #fff;
  margin: 0;
}
.composer-card img {
  perspective: 500px;
  position: absolute;
  width: calc(100% - 10px);
  margin-top: 5px;
  border-radius: 18px;
  height: 260px;
  object-fit: cover;
  object-position: top;
  filter: grayscale(100);
  backface-visibility: hidden;
}
/* Back of the card */
.composer-card-back {
  box-sizing: border-box;
  width: calc(100% - 10px);
  margin: 5px;
  background-color: #fff;
  border-radius: 17px;
  height: 260px;
  position: absolute;
  padding: 10px;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.composer-card-name-back{
  font-size: 20px;
  font-weight: 800;
}
.composer-card-noty-names{
  font-weight: 600;
  white-space:  nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin: 5px;
}
.composer-card-back-link{
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0;
  background-color: #393939;
  border-radius: 0 0 17px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 20%;
}
.composer-card-back-link p{
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  margin: 0;
  font-weight: 700;
}
@media screen and (min-width: 1190px) {
  .composer-card-container {
    width: 18%;
    margin: calc(1% - 3px);
  }
}
@media screen and (max-width: 1080px) {
  .composer-card-container {
    width: 46%;
    margin: calc(2% - 3px);
  }
}
@media screen and (max-width: 520px) {
  .composer-card-container {
    width: 100%;
    margin: 10px 0;
  }
}
