.odbor-card {
  margin: 0 25px 25px 25px;
  height: 160px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid var(--border-light);
  border-radius: 24px;
  flex-grow: 1;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #393939;
}
.odbor-card_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.odbor-card_title {
  font-size: 27px;
  font-weight: 800;
  margin: 10px 0 0 0;
  flex-shrink: 1;
}
.odbor-card_image {
  aspect-ratio: attr(width) / attr(height);
  width: auto;
  height: 90%;
  flex-shrink: 1;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-filter: drop-shadow(-4px 4px 3px #5353536e);
  filter: drop-shadow(-4px 4px 3px #5353536e);
    -webkit-animation-timing-function: ease-in-out; /* Chrome, Safari, Opera */
  animation-timing-function: ease-in-out;
    animation: float infinite 2s ease-in-out;
  -webkit-animation: float infinite 2s ease-in-out;
}
.odbor-card_image_hover {
  transform: translateY(-10px) scale(1.1);
  -webkit-filter: drop-shadow(-4px 4px 3px #5353536e);
  filter: drop-shadow(-4px 4px 3px #5353536e);
}
.button {
  border: 2px solid #3c3c3c;
}

@keyframes float {
  0% {
    transform: translateY(6px);
  }
  50% {
    transform: translateY(-6px) ;
    -webkit-filter: drop-shadow(-4px 4px 20px #5353536e);
    filter: drop-shadow(-4px 4px 20px #5353536e);
    
  }
  100% {
    transform: translateY(6px);
  }
}
