.sheet-music-container-main {
  margin: calc(90px + 2.5%) 2.5% 0;
  display: flex;
  flex-direction: row;
}
.sheet-music-left-side {
  flex-direction: column;
  width: auto;
  display: flex;
  padding-right: 50px;
}
.sheet-music-container-main .react-pdf__Page__canvas {
  height: 75vh !important;
  width: auto !important;
  aspect-ratio: 1/1.4142;
}
.sheet-music-right-side {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.sheet-music-container-main .react-pdf__message--loading {
  height: 75vh !important;
  aspect-ratio: 1/1.4142;
}

.sheet-music-container-main .loader-pdf {
  height: 75vh !important;
}
/* pdf controls */
.controls-pdf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
  margin: 10px 0;
  border: 1px solid var(--border-light);
  width: 100%;
  box-sizing: border-box;
}
.pdf-button {
  margin-left: 12px;
  border: none;
  font-family: "Rubrik-new";
  color: var(--text);
  font-weight: 700;
  padding: 8px 10px;
  border-radius: 6px;
  height: min-content;
  background: #c52b2c;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.235);
}
.pdf-button:first-of-type {
  margin-left: 0;
}
.controls-pdf p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-weight: 700;
  font-size: 13px;
  background-color: #fff;
  border-radius: 6px;
  margin: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.235);
}
/* pdf controls end */
.sheet-music-topbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--border-light);
}
.sheet-music-topbar p {
  margin: 0;
  font-weight: 800;
  margin-left: 20px;
}
.sheet-music-topbar span {
  margin-right: 20px;
}
.sheet-music-info {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid var(--border-light);
  padding: 15px;
  border-radius: 24px;
}
/* button */
.button-sheet-music-left,
.button-sheet-music-right {
  min-width: 130px;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 8px 10px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #fae3e3;
    transition: all 0.1s ease-in-out;
}
.button-sheet-music-left:hover,
.button-sheet-music-right:hover{
  background-color: #f5d8d8;
  cursor: pointer;
}
.button-sheet-music-left {
  margin-right: 10px;
  margin-bottom: 10px;
}
.button-sheet-music-right {
  margin-bottom: 10px;
  justify-content: space-between;
}
.main-button-sheet-music {
  background-color: #c52b2c;
  transition: all 0.1s ease-in-out;
}
.main-button-sheet-music:hover{
  background-color: #ad2323;
}
.main-button-sheet-music p {
  color: #fff !important;
}
.button-sheet-music-left img,
.button-sheet-music-right img {
  width: 25px;
}
.button-sheet-music-left p,
.button-sheet-music-right p {
  margin: 0;
  margin-right: 10px;
  font-weight: 800;
  font-size: 20px;
  color: #c52b2c;
}

.sheet-music-buttons a{
  justify-content: space-between;
}
.no-margin-right {
  margin-right: 0;
}
.no-margin-bottom{
  margin-bottom: 0;
}
.justify-content{
  justify-content: space-between;
}
.sheet-music-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 310px;
}

/* button end */
.embed-container{
  width: 100%;
}
.youtube-embed-sm{
margin-top: 20px;
width: 100%;
height: 100%;
max-height: 300px;
aspect-ratio: 16/9;
position: relative;
border-radius: 24px;
}