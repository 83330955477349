
.mobile-menu_item {
    background-color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(33.3333333333% - 20px) !important;
  padding: 8px 6px !important;
  border: 1px solid var(--border-light);
  border-radius: 6px !important;
  margin: 3px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.mobile-menu-item_container{
  display: flex !important;
  flex-wrap: wrap !important;
}
.mobile-menu_item:hover {
	transform: translateY(-3px) translateX(3px);
  box-shadow: -3px 3px 0px var(--border-light) !important;
}
.mobile-menu_item p {
  font-size: 15px;
  color: var(--text);
  font-weight: 700;
  margin: 0;
}
.odbor-header {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1020px) {
  .sidebar_container_main_header {
    display: none;
  }
  .odbor-main_content{
	width: 100% !important;
  }
}
@media screen and (max-width: 640px) {
  .mobile-menu_item {
	width: calc(50% - 20px);
  }
}
@media screen and (max-width: 480px) {
  .mobile-menu_item {
	width: calc(100% - 20px);
  }
}