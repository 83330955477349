.teacher-card-odbor{
	padding: 10px;
	border-radius: 12px;
	margin: 15px 0;
	background-color: #f6f8fa;
	transition: all 0.3s ease-in-out;
	color: var(--text);
	display: block;
}
.teacher-card-odbor:hover {
	transform: scale(1.01) translateY(-5px) !important;
	cursor: pointer;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.134);
}
.teacher-card-odbor:last-of-type{
	margin-bottom: 0;
}
.teacher-card-odbor-title{
	font-size: 1.4rem;
	font-weight: 800;
	margin-bottom: 1rem;
}
.margin-top-small-odbor{
	margin-top: 5px;
}
.teacher-card-odbor-description-contact{
	margin-top: 3px !important;
	font-weight: 700;
}