.warning_container {
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 999;
  backdrop-filter: blur(6px);
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  width: 100vw;
}
.warning-overflow-container {
  overflow-y: scroll;
  overflow-x: hidden;
}
.warning_container_closed {
  visibility: hidden;
  opacity: 0;
  top: 0;
  right: 0;
}
.warning_message {
  height: max-content;
  padding: 20px;
  color: white;
  background-color: #c52b2c;
  display: flex;
  flex-direction: column;
  width: 91%;
  border-radius: 24px;
  max-height: 90%;
}
.warning-close {
  transform: rotate(45deg);
  padding: 10px;
  width: 40px;
  height: 40px;
  transition: all 0.2s ease-in-out;
}
.warning-close:hover {
  transform: rotateZ(90deg);
}
.warning-close_div {
  border-radius: 12px;
  height: 60px;
  width: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.warning-close_div:hover {
  background-color: #fff;
  transform: scale(1.1);
  box-shadow: 0px 0px 10px rgba(91, 91, 91, 0.683);
}
.warning_container h3 {
  margin: 20px 0 0 0;
  font-size: 50px;
  font-weight: 900;
}
.warning-text {
  margin: 0 20px;
  position: relative;
  font-size: 20px;
  width: auto;
  overflow-wrap: anywhere;
}
.warning-text strong {
  color: white;
  font-weight: 900;
}
.warning-text a {
  text-decoration: none;
  color: #fff;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.warning-text a:hover {
  color: #3c3c3c;
}
.warning-text p {
  margin: 0;
}
.warning-text a:before {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -1px;
  border-radius: 2px;
  left: 0;
  background: #fff;
  transition: all 0.3s ease-in-out;
  display: table-cell;
}
.warning-text a:hover:before {
  height: 100%;
}
/* closed menu */
.file-container-warning {
  border: none;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}
.file-container-warning li {
  align-items: center;
  display: flex;
  width: fit-content;
  list-style: none;
  padding: 10px;
  width: calc(50% - 20px) !important;
  flex-wrap: wrap;
}
.filename-warning {
  height: fit-content;
  color: #fff !important;
  margin: 0 10px;
  width: fit-content;
  flex: 1 0 0;
  color: var(--text);
  transition: all 0.2s ease-in-out;
  word-break: break-all;
}
.file-warning {
  width: 96%;
  margin-top: 0 !important;
}
.warning_message .file {
  margin: 20px;
}
.warning_message .filename,
.warning_message .filesize {
  color: #fff;
}

@media screen and (max-width: 1150px) {
  .file-container-warning li {
    width: 100% !important;
  }
}
@media screen and (max-width: 720px) {
  .warning_container h3 {
    margin: 20px 0 0 0;
    font-size: 30px;
    font-weight: 900;
  }
  .warning-text {
    font-size: 16px;
  }
  .warning-overflow-container .file {
    margin: 0;
    width: 100%;
  }
  .file h2 {
    margin-top: 0;
  }
  .warning_message {
    width: 100vw;
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    border-radius: 0;
  }
}
