.desktop-menu {
  background-color: #fff;
  position: absolute;
  display: flex;
  width: 200px;
  border: 1px solid var(--border-light);
  border-radius: 12px;
  padding: 15px;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  left: 50%;
  top: 100%;
  transform: translateX(-47%) rotateX(-15deg);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform-origin: center -100px;
  visibility: hidden;
}
.desktop-menu-shown {
  visibility: visible;
  transform:  translateX(-47%) rotateX(0deg);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.desktop-menu::before {
  left: 100px;
  top: -9px;
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-top-left-radius: 6px;
  background-color: #fff;
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  transform: rotate(45deg);
}
.desktop-menu-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.desktop-menu-item {
  color: var(--text);
  font-weight: 700;
  margin: 8px 0;
  position: relative !important;
}
