.about-bottom {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  max-width: 112rem;
  margin: 0 auto;
  position: relative;
}
.about-bottom-text {
  z-index: 2;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}
.about-bottom-text h3 {
  padding: 10px;
  font-size: 50px;
  font-weight: 800;
  margin: 0 0 20px 0;
  width: fit-content;
}
.about-bottom-text-content {
  border-radius: 24px;
  margin: 0;
  width: fit-content;
  padding:20px;
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(to right, #fff, #fff),
    linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 4px solid transparent;
}
.bottom-main-image {
  margin: 40px 40px 40px 0;
  max-width: calc(50% - 80px);
  height: auto;
  -webkit-filter: drop-shadow(-4px 4px 20px #5353536e);
  filter: drop-shadow(-4px 4px 20px #5353536e);
  z-index: 2;
}
.lines-bottom {
  margin-top: -110px;
  z-index: 1;
  width: 100%;
  position: absolute;
}
@media screen and (max-width: 1100px) {
  .lines-bottom {
    margin-top: -300px;
  }
  .about-bottom-text h3 {
    font-size: 35px;
  }
  .about-bottom-text p {
    font-size: 20px;
  }
      .about-bottom-text-content .button p{
    font-size: 15px;
  }
}
@media screen and (max-width: 789px) {
  .about-bottom-text h3 {
    font-size: 30px;
  }
  .about-bottom-text p {
    font-size: 18px;
  }
    .about-bottom-text-content .button p{
    font-size: 15px;
  }
}
@media screen and (max-width: 656px) {
  .about-bottom {
    flex-direction: column;
  }
  .about-bottom-text-content .button{
    width: min-content  !important;
  }
   .about-bottom-text-content .button_content{
    width: max-content !important;
   } 
  .about-bottom-text-content{
    width: calc(90% - 40px);
  }
  .about-bottom-text {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .about-bottom-text h3 {
    font-size: 30px;
  }
  .about-bottom-text p {
    font-size: 18px;
  }
  .bottom-main-image {
    margin: 40px 0 0 0;
    max-width: 80%;
  }
  .lines-bottom {
  margin-top: 350px;
  z-index: -1;
  width: 100vw;
  position: absolute;
}
}