.teacher_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 90px;
  width: 100%;
  margin-bottom: 90px;
}
.teacher_container h3 {
  text-align: center;
  margin: 50px 0;
  font-size: 50px;
  font-weight: 800;
}
.teacher-card_container {
    max-width: 112rem;
  width: 95%;
  border: 4px solid transparent;
  border-radius: 48px;
  background: linear-gradient(to right, white, white),
    linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.teacher-card-wrapper-smaller {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  flex-wrap: wrap;
}
.teacher-card_container_container {
  display: flex;
  flex-wrap: wrap;
}
.teacher-card-parent {
  display: flex;
  flex: 0 0 46%;
  margin: 2%;
  text-decoration: none !important;
}
.teacher-card {
  padding: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px solid #cdcdcd;
  border-radius: 24px;
}
.teacher-photo {
  border-radius: 12px;
  height: 12em;
  width: 10em;
  object-fit: cover;
}
.teacher-info_container {
  height: fit-content;
  flex-grow: 1;
  padding: 0 30px;
}
.teacher-name {
  margin: 0 0 5px 0;
  font-size: 20px;
  font-weight: 800;
}
.teacher-title {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
}
.card-small {
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin: 10px 0 0 0;
  padding: 5px 10px;
  width: fit-content;
  background-color: #f15a2445;
  transition: all 0.3s ease-in-out;
  flex-wrap: wrap;
  flex-shrink: 1;
}
.card-small:hover {
  transform: translateX(4px) translateY(-4px);
  box-shadow: -4px 4px 0  #e23f3f97;
}
.card-small img {
  height: 20px;
}
.card-small a {
  text-decoration: none;
  color: #c52b2c;
  font-size: 16px;
  font-weight: 800;
  margin: 0 0 0 10px;
}
.card-small p {
  text-decoration: none;
  color: #c52b2c;
  font-size: 16px;
  font-weight: 800;
  margin: 0 0 0 10px;
}
@media screen and (max-width: 1400px) {
  .teacher-card-parent {
    width: 50%;
  }
  .teacher-photo {
    margin: 0 0 10px 30px;
  }
  .teacher-card {
    align-items: flex-start;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .teacher-photo {
    margin: 0;
  }
  .teacher-info_container {
    padding: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1020px) {
  .teacher-card_container {
    background: none;
  }
  .teacher_container h3 {
    font-size: 40px;
  }
  .teacher-card-parent {
    width: 100%;
    flex: 0 0 96%;
  }
  .teacher-card {
    padding: 4%;
  }
}
@media screen and (max-width: 389px) {
.card-small a{
  font-size: 12px;
}
.card-small p{
  font-size: 12px;
}
}
  