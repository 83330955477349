.composer-image {
  width: 18%;
  aspect-ratio: 1/1.2;
  object-fit: cover;
  object-position: top;
  border-radius: 12px;
}
.composer-container-top {
  display: flex;
  margin: 2%;
  padding-top: 120px !important;
}
.composer-info_container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 2%;
}
.composer-info-top {
  height: min-content;
  border-radius: 12px;
  padding: 30px 12px 10px;
}
.composer-info-top h2 {
  margin: 0;
  font-size: 40px;
  font-weight: 800;
}
.composer-top-filtering {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  flex-grow: 1;
}
.composer-top-filtering-filter-word {
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 5px;
}
.composer-info_container .difficulty {
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
}
.composer-info_container label {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.composer-info_container label input {
  margin-right: 10px;
}
.difficulty-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.composer-top-filtering .file-search {
  margin-top: 20px !important;
}
/* Thumbnails */
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  border-radius: 12px;
  border: 1px solid var(--border-light);
}
.react-pdf__Page__textContent {
  width: 100% !important;
  position: relative !important;
  display: none;
}
.composer-sheet-music-thumbnail-container-big {
  border-radius: 12px;
  width: 18%;
  margin-bottom: 2.5%;
  min-width: 18% !important;
  min-height: 24.47vw !important;
}
.composer-sheet-music-thumbnail-container {
  min-width: 18% !important;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: none;
  background-color: #fff !important;
}
.sheet-music-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
  margin: 2%;
  gap: 2.5%;
}
.sheet-music-thumbnail-info {
  position: absolute;
  height: 35%;
  border-radius: 0 0 11px 11px;
  width: 100%;
  background: linear-gradient(360deg, #000000 10%, transparent 100%);
  color: #fff;
  font-weight: 600;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  bottom: 0;
  left: 1px;
}
.sheet-music-thumbnail-info p {
  margin: 0;
}
.sheet-music-name {
  margin-bottom: 5px !important;
  font-size: 20px;
  font-weight: 900;
  margin: 0;
}
.sheet-music-difficulty-thumbnail {
  position: absolute;
  top: 0px;
  margin: 15px;
  right: -2px;
  z-index: 1;
}
.easy-sheet-music,
.medium-sheet-music,
.hard-sheet-music {
  color: #fff;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 15px;
}
.easy-sheet-music {
  background-color: #11965e;
  box-shadow: 0 0 10px #11965e;
}
.medium-sheet-music {
  background-color: #c39002;
  box-shadow: 0 0 10px #c39002;
}
.hard-sheet-music {
  background-color: #c52b2c;
  box-shadow: 0 0 10px #c52b2c;
}
.composer-sheet-music-thumbnail {
  min-width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-pdf {
  font-weight: 800;
  color: #393939;
}
.sheet-music-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.composer-sheet-placeholder-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}
.sheet-music-warning {
  position: absolute;
  font-weight: 700;
  z-index: 4;
}
@media screen and (max-width: 1189px) {
  .sheet-music-thumbnail-info {
    height: 70%;
  }
  .composer-sheet-music-thumbnail-container {
    min-height: 24.47vw !important;
    height: 24.47vw !important;
  }
}
@media screen and (max-width: 850px) {
  .composer-container-top {
    flex-direction: column;
    align-items: center;
  }
  .composer-info_container {
    width: 100%;
    margin: 0 2% 0 2%;
  }
  .composer-info-top {
    text-align: center;
    padding: 10px;
  }
  .composer-image {
    display: none;
  }
  .sheet-music-container {
    gap: 3.5%;
  }
  .composer-sheet-music-thumbnail-container-big {
    width: calc(31% - 1px);
    min-width: calc(31% - 1px);
    min-height: 43.8402vw !important;
    height: 43.8402vw !important;
  }
  .composer-sheet-music-thumbnail-container {
    height: 41.9402vw !important;
    min-height: 41.9402vw !important;
  }
}
@media screen and (max-width: 600px) {
  .composer-sheet-music-thumbnail-container-big {
    width: 48%;
    min-width: 48%;
    min-height: 67.8816vw !important;
    height: 67.8816vw !important;
  }
  .composer-sheet-music-thumbnail-container {
    height: 65.1816vw !important;
  }
}
@media screen and (max-width: 400px) {
  .composer-sheet-music-thumbnail-container-big {
    width: 100%;
    min-width: 100%;
    min-height: 141.42vw !important;
    height: 141.42vw !important;
  }
  .difficulty-container {
    flex-wrap: wrap;
  }
  .composer-sheet-music-thumbnail-container {
    height: 136.42vw !important;
  }
}
