.NotyMainPage {
  padding: calc(90px + 2.5%) 2.5% 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
}
.noty-container-main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 112rem;
}
