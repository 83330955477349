* a {
  text-decoration: none;
}
* u {
  text-decoration-thickness: 2px;
}
body {
  overflow-x: hidden !important;
  margin: 0;
  font-family: "rubrik-new", sans-serif;
  font-style: normal;
  color: #393939 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8fa;
}
:root {
  --border-light: #cdcdcd;
  --text: #393939;
  --form-control-color: #f15a24;
}
.termsfeed-com---palette-light.termsfeed-com---nb {
  border-radius: 20px;
  margin: 0 20px 20px 0;
  font-family: "rubrik-new";
  background-color: #fff;
  border: #cdcdcd 1px solid;
}
.termsfeed-com---palette-light .cc-nb-title {
  color: #f15a24 !important;
  font-weight: 800;
  font-size: 30px;
}
.termsfeed-com---palette-light .cc-nb-text {
  color: #393939 !important;
  font-weight: 700;
}
.termsfeed-com---palette-light .cc-nb-okagree,
.termsfeed-com---palette-light .cc-cp-foot-save {
  background-color: #f15a24 !important;
  font-weight: 900;
  background-color: transparent;
  border-radius: 12px;
}
.termsfeed-com---palette-light .cc-nb-changep,
.termsfeed-com---palette-light .cc-nb-reject {
  background-color: #f15a2445 !important;
  color: #393939 !important;
  border-radius: 12px;
  flex: 1;
  font-weight: 900;
  margin-left: 8px;
}
.cc-nb-buttons-container button:hover,
.termsfeed-com---palette-light .cc-cp-foot-save:hover {
  transform: scale(1.05);
}
/*consent window*/
.termsfeed-com---palette-light .cc-cp-foot-byline {
  visibility: hidden;
}
.termsfeed-com---palette-light .cc-pc-container {
  border-radius: 24px;
  font-family: "rubrik-new";
}
.termsfeed-com---palette-light .cc-pc-head {
  border-radius: 24px 24px 0 0 !important;
}
.termsfeed-com---palette-light .cc-cp-foot {
  border-radius: 0 0 24px 24px !important;
}
.termsfeed-com---palette-light .cc-pc-head-close:active {
  border-radius: 12px;
  border: 2px solid #393939;
}
.termsfeed-com---pc-dialog .cc-cp-body-tabs {
  font-family: "rubrik-new" !important;
}
.termsfeed-com---palette-light .cc-cp-body-tabs-item-link:focus {
  box-shadow: -6px 0px 0 #fff;
  border-radius: 6px 0 0 6px;
}
.termsfeed-com---palette-light .cc-pc-head-lang select:focus {
  box-shadow: 0px 0px 0 3px #3c3c3c;
  border-radius: 6px;
}
.termsfeed-com---pc-dialog .cc-cp-body-tabs-item:last-of-type {
  border-bottom: 0;
}

/* checkbox */
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: #393939;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]:checked {
  background-color: #393939;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #393939;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
  box-shadow: inset 1em 1em #fff;
}
@media screen and (max-width: 1045px) {
  .termsfeed-com---palette-light .cc-nb-changep,
  .termsfeed-com---palette-light .cc-nb-reject {
    margin-left: 0;
  }
}
@media screen and (max-width: 1012px) {
  .cc-nb-okagree {
    width: 100%;
  }
  .cc-nb-changep,
  .cc-nb-reject {
    width: calc(50% - 4px);
  }
  .cc-nb-changep {
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .termsfeed-com---palette-light .cc-nb-changep {
    margin-left: 0;
  }
}
@media screen and (max-width: 742px) {
  .termsfeed-com---nb .cc-nb-main-container{
    width: 100%;
  }
  .termsfeed-com---nb-simple {
    width: calc(100% - 40px) !important;
    max-width: calc(100% - 40px) !important;
  }
}
@media screen and (max-width: 360px) {
  .termsfeed-com---nb-simple {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
