.header-container_main {
  width: 100% !important;
  display: flex;
  justify-content: center;
  max-width: 112rem;
  margin: 0 auto;
}
.header {
  border-radius: 12px;
  margin-top: 2.5%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border-light) solid 1px;
  background: none;
  width: 95%;
  z-index: 990;
  position: fixed;
  background-color: #fff;
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  background-color: rgb(228 228 228 / 15%);
  transition: all 0.3s ease-in-out;
}
.header-items-lupa {
  cursor: pointer;
  display: flex;
}
.logo {
  aspect-ratio: attr(width) / attr(height);
  width: auto;
  height: 50px;
  padding: 20px;
  margin-left: 0px;
}
.lupa {
  height: 50px;
  padding: 20px;
  margin-right: 0px;
  stroke-width: 20px !important;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.lupa-strokes {
  cursor: pointer;
  stroke-width: 30px !important;
  transition: all 0.15s ease-in-out;
}
.lupa:hover {
  transform: scale(1.1) rotate(20deg);
}
.lupa-hover {
  stroke: #f15a24;
}
.none {
  display: none;
}
.colorful {
  background: -webkit-linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  display: inline-block !important;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}
.colorful-hover:hover {
  display: inline-block !important;
  background: -webkit-linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}
.headerItem_Container {
  display: flex;
  margin-right: 20px;
}
.headerItem_List {
  perspective: 2000px;
  position: relative;
  list-style-type: none;
  padding: 1em;
}
.headerItem {
  text-decoration: none;
  color: #393939 !important;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  position: relative;
  margin: 0;
}

.link:before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  border-radius: 50px;
  bottom: -6px;
  left: 0;
  background: linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.link:hover:before {
  visibility: visible;
  width: 100%;
}

.link-visible:before,
.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  bottom: -6px;
  left: 0;
  background: linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.hamburger {
  display: none;
}
@media screen and (min-width: 1020px) {
  .header {
    background-color: #fff;
    margin-top: 1%;
    width: 98%;
  }
}
@media screen and (max-width: 1070px) {
  .logo {
    margin-left: 0;
    align-self: center;
  }
  .lupa {
    margin-right: 0;
  }
  .header {
    justify-content: space-between;
  }
}
@media screen and (max-width: 570px) {
  .header {
    justify-content: space-between;
    height: 60px;
  }
  .logo {
    height: 40px;
    width: 100%;
    padding: 0;
  }
  .lupa {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .hamburger {
    width: 70px;
    height: 70px;
  }
}
