.photo-container_main {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.photo-container {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 24px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}
.blog-image-container {
  width: calc(25% - 40px);
  padding: 20px;
}
.blog-image-container-smaller {
  padding-top: 100%;
  display: block;
  border-radius: 12px;
  position: relative;
  transition: 0.2s all ease-in-out;
}
.blog-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.blog-image-container-smaller:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.185);
  cursor: pointer;
}
@media screen and (max-width: 1100px) {
  .blog-image-container {
    width: calc(33.33% - 40px);
  }
}
@media screen and (max-width: 620px) {
  .blog-image-container {
    width: calc(50% - 40px);
  }
}
.video-badge-blog {
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  z-index: 2;
  background: #fff;
  border: 1px solid var(--border-light);
  border-radius: 6px;
  margin-top: 10px;
  margin-left: 10px;
}
.video-badge-blog p {
  margin: 0;
}
