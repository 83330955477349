
@media screen and (max-width: 820px) {
  .main-odbor_container {
    justify-content: center;
    width: 100% !important;
  }
  .odbor-main_content {
    width: 100%;
  }
  .sidebar {
    display: none;
  }
  .odbor-header{
    font-size: 30px;
  }
.photo-container_main{
  width: 100%;
}
.file{ 
  width: 100%;
}
}
