.background-menu {
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  width: 100vw !important;
  height: 100vh !important;
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 999 !important;
  margin-top: calc(-2.5% - 2px);
  margin-right: calc(-2.5% - 2px);
}
.x {
  aspect-ratio: attr(width) / attr(height);
  position: absolute;
  margin: -21px 0 0 -21px;
  width: 60px;
  height: 60px;
  border-radius: 12px 0 12px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-light);
  transition: all 0.3s ease-in-out;
}
.x .warning-close {
  padding: 5px;
  aspect-ratio: attr(width) / attr(height);
}
.onclick_div {
  background-color: rgba(81, 81, 81, 0.302);
  position: absolute;
  transition: all 0.3s ease-in-out;
}
.mobile-menu_background {
  height: 100vh;
  width: 100vw;
  visibility: visible;
  opacity: 1;
}
.mobile-menu_background_hidden {
  opacity: 0;
  visibility: hidden;
}
.background-menu-hidden {
  background-color: rgba(255, 114, 96, 0);
  width: 100% !important;
  opacity: 0;
  visibility: hidden;
}
.mobile-menu {
  border-radius: 12px;
  margin-top: 2.5%;
  margin-right: 2.5%;
  width: 235px;
  height: 90%;
  padding: 20px;
  background-color: #fff;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  background-color: #f6f8fa;
  border: 1px solid var(--border-light);
  z-index: 999;
}
.mobile-menu-hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  width: 0px;
}
.mobile-menu-list {
  padding: 10px;
  margin-top: 40px;
}
.mobile-menu-item {
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid var(--border-light);
}
.menu-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu-link {
  position: relative;
  text-decoration: none;
  color: var(--text);
  font-size: 1.6rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.submenu-item {
  width: max-content;
  position: relative;
  color: var(--text);
  margin: 5px;
  font-size: 1.2rem;
  list-style: none;
  font-weight: 700;
  padding: 2px;
}
.submenu-item::before {
  bottom: -2px;
}
.arrow-down-open {
  width: 30px;
  height: 30px;
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
.arrow-up-closed {
  transform: rotate(0deg);
}
.active-arrow {
  opacity: 1;
  padding-left: 0;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.non-active-arrow {
  opacity: 0;
  visibility: hidden;
}
.submenu {
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}
.submenu-hidden {
  height: 0;
  overflow: hidden;
}
@media screen and (max-width: 1020px) {
  .active{
    color: #f15a24;
  }
  .active:before{
    display: none;
  }
}
@media screen and (max-width: 570px) {
  .background-menu {
    justify-content: center;
  }
  .mobile-menu {
    width: 85%;
    margin-right: 0;
  }
  .mobile-menu-hidden {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(100%) !important;
    width: 235px !important;
  }
}
