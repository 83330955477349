.footer_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0 !important;
}
.footer_container_inside {
  padding: 10px;
  width: calc(100% - 20px);
}
.footer_container a:hover {
  color: #f15a24;
}
.footer_container a {
  transition: 0.12s all ease-in-out;
  color: #fff;
  font-weight: 800 !important;
}
.footer_top {
  display: flex;
  flex-direction: row;
}
.footer_card {
  background-color: #393939;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  border-right: 1px solid #fff;
  flex-grow: 1;
}
.footer_card:first-of-type {
  border-top-left-radius: 12px !important;
}
.footer_card:last-of-type {
  border-top-right-radius: 12px !important;
}
.footer_card p {
  margin: 0;
  font-weight: 600;
}
.footer-title {
  font-size: 25px !important;
  font-weight: 800 !important;
  margin: 0 0 10px 0 !important;
}
.footer_card a {
  color: #fff;
  font-weight: 600;
}
.footer_socials img {
  width: 30px;
  height: 30px;
  margin: 7px 20px;
}
.footer_socials div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer_copyright {
  border-radius: 0 0 12px 12px !important;
  font-weight: 700;
  background-color: #393939;
  color: #fff;
  padding: 10px 100px 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #fff;
}
.footer_copyright a {
  color: #fff;
}
@media screen and (max-width: 1282px) {
  .footer_top {
    flex-direction: column;
  }
  .footer_card {
    border-top: 1px solid #fff;
    border-right: 0px;
  }
  .footer_card:first-of-type{
    border-radius: 12px 12px 0 0;
  }
.footer_card:last-of-type {
    border-top-right-radius: 0px !important;
}
}
@media screen and (max-width: 770px) {
  .footer_card {
    padding: 20px;
  }
  .footer-title {
    font-size: 20px;
  }
  .footer_card p {
    font-size: 14px;
  }
  .footer_copyright {
    padding: 10px 10px 26px 10px;
    flex-direction: column;
    text-align: center;
  }
}
