.odbor-predmet-container{
	width: 100%;
}

.odbor-predmet-small-container{
	border: 1px solid var(--border-light);
	background-color: white;
	padding: 15px;
	width: calc(100% - 30px);
	margin: 16px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
}
.arrow-odbor{
	height: 30px;
}
.odbor-predmet-small-header{
	font-size: 1.2rem;
	font-weight: 900;
}
.odbor-predmet-small-container p{
	margin: 0;
}
.odbor-predmet-small{
	width: 100%;
	justify-content: space-between;
	align-items: center;
	display: flex;
	flex-direction: row;
}
.odbor-predmet-teachers-container{
	width: 100%;
}