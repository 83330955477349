.news-container-big {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  position: relative;
}
.card-parent {
  height: 100%;
  width: 29.3%;
  flex: 0 0 29.3%;
  margin: 2%;
  text-decoration: none !important;
}
.card-info {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  font-size: 140%;
  height: 350px;
  padding: 20px;
  border: 1px solid var(--border-light);
  border-radius: 24px;
  background-color: #e5eaec;
}
.news-container {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 !important;
  margin: 0;
  width: 100%;
}
.news-card {
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 350px;
  color: #393939;
  border: 1px solid var(--border-light);
  text-overflow: ellipsis;
}
.card-date {
  margin: 0;
  margin-top: 10px;
  font-weight: 800;
}
.card-title {
  margin: 0;
  font-size: 25px;
  font-weight: 900;
}
.card-news-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-image {
  border-radius: 12px;
  margin: 0 0 15px 0;
  height: 20px;
  flex-grow: 1;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.card-full {
  height: 100vw;
  width: 100vw;
}
@media screen and (max-width: 1050px) {
  .news-container {
    justify-content: center;
  }
  .card-parent {
    flex: 1 0 30%;
    min-width: 10px;
  }
}
@media screen and (max-width: 680px) {
  .news-container {
    justify-content: center;
  }
  .card-parent {
    flex: 0 0 90vw;
  }
  .news-card {
    margin-top: 20px;
  }
  .card-info {
    margin-top: 30px;
    height: 30%;
  }
}
