.file-container li {
  align-items: center;
  display: flex;
  width: fit-content;
  list-style: none;
  padding: 10px;
  width: calc(50% - 20px);
  flex-wrap: wrap;
}
.filename {
  height: fit-content;
  margin: 0 10px;
  width: fit-content;
  flex: 1 0 0;
  color: var(--text);
  transition: all 0.2s ease-in-out;
  word-break: break-all;
}
.link-file-a {
  align-items: center;
  display: flex;
  width: fit-content;
  flex: 1 0 0;
  flex-direction: row;
}
.file-badge {
  justify-content: center;
  width: 35px !important;
  transition: all 0.15s ease;
  line-height: 1.7em;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  height: 20px;
  text-decoration: none;
  border-radius: 5px;
}
.file-badge:hover {
  cursor: pointer;
}
.file-badge p {
  height: max-content;
  font-weight: 800;
  margin: 0;
}
@media screen and (max-width: 1150px) {
  .file-container li {
    width: 100%;
  }
}
.file-info {
  display: flex;
  flex-direction: column;
}
.filesize {
  font-size: 10px;
  margin: 0 10px;
  color: var(--text);
  font-weight: 800;
}
/* file sorter */
.file-sorter-container {
  box-sizing: border-box;
  background-color: #fff;
  border: solid 1px var(--border-light);
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: 100%;
  padding: 15px;
}
.file-sorter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.filter-file-header {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 800;
  margin: 0;
}
.sorting-button {
  border: solid 1px var(--border-light);
  font-weight: 700;
  margin: 0;
  padding: 5px 10px;
  border-radius: 6px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: flex;
  align-items: center;
}
.sorting-button-hover:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.192);
  border: solid 1px transparent;
  transform: translateY(-3px);
  background-color: #fff;
}
.sorting-button-active {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.192);
  transform: translateY(-3px);
  background: #f6f8fa;
}
.file-sorter p:first-of-type {
  margin-left: 0;
}
.file-sorter p:last-of-type {
  margin-right: 0;
}
.file-sorter-arrow {
  height: 20px;
  margin-left: 10px;
  transition: all 0.15s ease-in-out;
}
.file-sorter-arrow-up {
  transform: rotate(180deg);
}
.file-filter-name-badge {
  margin: 0;
}
.file-search {
  margin-top: 10px;
}
.file-search input {
  border-radius: 6px;
  width: calc(100% - 22px);
  height: 30px;
  font-size: 16px;
  border: solid 1px var(--border-light);
  background-color: #f6f8fa;
  padding: 2.5px 10px;
  font-family: "rubrik-new", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  color: var(--text);
}
.file-search input::-webkit-input-placeholder {
  color: #787878;
}
.no-files-found{
  padding: 0 0 0 15px;
  font-weight: 700;
}
@media screen and (max-width: 550px) {
  .file-sorter {
    flex-direction: column;
  }
  .sorting-button {
    margin: 0;
    width: calc(100% - 22px);
    justify-content: space-between;
    margin-top: 5px;
  }
  .sorting-button:first-of-type {
    margin-top: 0;
  }
  .sorting-button-active {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.192);
    border: solid 1px transparent;
    transform: translateY(-2px);
  }
}
