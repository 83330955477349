.button {
  display: flex;
  border-radius: 12px;
  width: max-content;
  font-family: "rubrik-new", sans-serif;
  font-weight: 800;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  padding: 8px 6px 8px 12px;
}
.button:hover {
  cursor: pointer;
}
.button_content {
  margin: 0 !important;
  padding-right: 10px;
  width: max-content;
}
.arrow {
  width: auto;
  aspect-ratio: attr(width) / attr(height);
  height: 10px;
}
.arrow-line {
  visibility: hidden;
  opacity: 0;
  fill: #fff;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px !important;
    transition: all 0.2s ease-in-out !important;
}
