.odbory-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  height: auto;
}
.odbory {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  width: 95%;
  height: max-content;
  border: 4px solid transparent;
  border-radius: 48px;
  background: linear-gradient(to right, #f6f8fa, #f6f8fa),
    linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  z-index: 2;
}
.h3-odbory {
  font-weight: 800;
  text-align: center;
}
.odbory-info {
  z-index: 2;
  box-sizing: border-box !important;
  padding: 50px !important;
  font-size: 200%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #e5eaec;
  border: 1px solid var(--border-light);
  border-radius: 24px;
  margin: 25px;
  flex: 1 1 auto;
}
.odbory-cards-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
  flex-grow: 1;
  height: 100%;
  z-index: 6;
}
.odbor-card:hover {
  transform: scale(1.02);
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #9f9f9f00;
}
@media screen and (max-width: 1050px) {
  .odbory-container {
    height: auto;
  }

  .odbory-cards-parent {
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .odbory-info {
    width: calc(100% - 70px);
    margin: 35px 25px 25px 25px;
    border-radius: 12px;
  }
  .odbory {
    width: calc(90% - 7px);
    flex-direction: column;
    height: auto;
    padding-bottom: 10px;
    border-radius: 24px;
  }
  .odbor-card {
    width: calc(100% - 100px) !important;
    border-radius: 12px !important;
    margin-top: 0 !important;
  }
  .odbor-card .button {
    border-radius: 6px;
  }
}
@media screen and (max-width: 500px) {
  .odbory-container {
    margin-top: 32px;
  }
  .odbory-info {
    margin: 8px !important;
    width: calc(100% - 16px);
    height: 10% !important;
    padding: 8px !important;
  }
  .odbory {
    padding-bottom: 0;
  }
  .odbor-card {
    height: auto !important;
    flex-direction: column !important;
    align-items: center !important;
    margin: 8px !important;
    width: calc(100% - 30px - 18px) !important;
  }
  .odbor-card_image {
    width: 60% !important;
    height: auto !important;
  }
  .odbor-card_title {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
}
