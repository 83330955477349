.mainPart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: calc(90px + 2.5%) !important;
  position: relative;
  max-width: 112rem;
  margin: 0 auto;
  height: 50rem;
  justify-content: flex-start;
  top: 0;
}
.mainText_Container {
  padding: 3rem 173px 0 5rem;
}
.mainText_Header {
  margin: 0 !important;
  font-weight: 900;
  font-size: 3.5rem;
}
.description {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 35vw;
  font-weight: 600;
}
.button-cluster_main {
  display: flex;
  flex-direction: row;
}
.button-left {
  margin-left: 10px;
}
.instruments {
  position: absolute;
  right: 0;
  height: 50rem;
  width: auto;
}
.lines-hero {
  position: absolute;
  top: 650px;
  width: 100%;
  z-index: -1;
}
@media screen and (max-width: 1200px) {
  .instruments {
    height: 67vw;
  }
}
@media screen and (max-width: 1020px) {
  .mainPart {
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    padding-top: calc(90px + 2.5%) !important;
    height: auto;
  }
  .instruments {
    display: none;
  }
  .mainText_Container {
    align-items: center;
    padding: 0;
  }
  .mainText_Header {
    padding: 4rem 0 2rem 0;
    display: flex;
    text-align: center;
    width: 100%;
    font-size: 2rem;
  }
  .description {
    font-size: 1rem;
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
  .mainText_Header br {
    display: none;
  }
  .mainText_Container .button {
    display: none;
  }
}
@media screen and (max-width: 570px) {
  .mainText_Header {
    padding: 2rem 0 2rem 0;
    font-size: 1.9rem;
  }
}
