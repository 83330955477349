.not-auth-container {
  margin-top: 120px;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
}
.not-auth-container .lf-player-container {
  max-height: calc(20vh - 120px);
  position: relative;
}
.not-auth-container #lottie {
	flex: 1;
	height: 60vh;
}
.not-auth-container #lottie svg {
  max-height: 100%;
}
