.fof-container{
	padding-top: calc(90px + 2.5%);
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	height: calc(97.5vh - 160px) !important;
}
.fof-container h1{
	font-size: 3rem;
	color: #f15a24;
		margin: 2rem;
}
.fof-num{
	font-size: 10rem;
	font-weight: bold;
	color: #f15a24;
	margin: 0;
}
.vinyl{
	position: absolute;
	width: 8rem;
	height: auto;
	margin-left: -1rem;
}
.fof-text{
	font-size: 1.5rem;
	margin: 0;
}
.fof-text a{
	color: #f15a24;
	text-decoration: underline;
}
.man-fof{
	width: 300px;
    right: 90px;
	bottom: 0;
	position: fixed;
	z-index: 999;
}