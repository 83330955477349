.search_container {
  left: 0;
  top: 0;
  margin-top: calc(-1% - 2px);
  margin-left: calc(-1%);
  width: 100vw;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 9999;
  background-attachment: fixed;
  transition: all 0.2s ease-in-out;
  background-color: rgba(51, 51, 51, 0.5);
  opacity: 1;
}
.ais-Hits--empty {
  visibility: hidden;
  opacity: 0;
}
.search_container h4 {
  margin: 20px 20px 20px 0;
  font-size: 30px;
}
.search-close_div {
  border-radius: 12px;
  height: 60px;
  width: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-light);
  transition: all 0.2s ease-in-out;
}
.search-close_div:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: -4px 4px 10px 0px rgba(57, 57, 57, 0.158);
}
.search-dialog {
  border-radius: 24px;
  background-color: #f2f2f2;
  border: 1px solid var(--border-light);
  width: 50%;
  height: 76%;
  padding: 15px;
  white-space: wrap;
  visibility: visible;
  overflow: scroll;
}
.search-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-results input {
  font-weight: 700;
  font-family: "rubrik-new";
  font-size: 20px;
  width: 100%;
  border: 1px solid var(--border-light);
  border-radius: 12px;
  height: 50px;
  padding: 0 10px;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.search-results {
  display: flex;
  flex-direction: column;
}
.ais-SearchBox-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-thumbnail-search {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.search-result-div {
  display: flex;
  align-items: center;
}
.ais-Hits-item a {
  text-decoration: none;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
  padding: 0 20px;
}
.small-mag-glass {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.search-result-div-smaller {
  display: flex;
  align-items: center;
}
.ais-Highlight-highlighted {
  font-weight: 800;
  background: -webkit-linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}
.ais-Hits-item {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border-light);
}
.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}
.results {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
.results h3 {
  margin: 0 !important;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
}
.ais-Hits-list {
  margin: 0;
  padding: 0 0 0;
}
.ais-Hits-item::marker {
  content: "";
}
@media screen and (max-width: 768px) {
  .search-dialog {
    width: 75%;
  }
}
@media screen and (max-width: 1020px) {
  .search_container {
    margin-top: calc(-2.5% - 2px);
    margin-left: calc(-2.5%);
  }
  .search-dialog{
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .search-dialog {
    width: 92%;
    height: 95%;
    margin: 0;
  }
}
