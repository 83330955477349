.main-odbor_container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}
.sidebar_container {
  background-color: #ffffff;
  border-right: 1px solid var(--border-light);
}
.sidebar {
  top: 90px !important;
  position: sticky;
  height: 81vh;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0;
}
.sidebar_item {
  position: relative;
  padding: 5px 30px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.sidebar a {
  text-decoration: none;
}
.sidebar_item p,
.sidebar_item a {
  margin: 0;
  color: var(--text);
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  line-height: 1.6;
  position: relative;
}
.odbor-main_content {
  background-color: #f6f8fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 300px);
  margin-top: calc(90px + 2.5%) !important;
}
.odbor-top {
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  border-bottom: 1px solid var(--border-light);
  border-top: 1px solid var(--border-light);
  padding: 0 20px;
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  width: 80% !important;
  padding: 0 20px !important;
}
.breadcrumbs li {
  margin: 0 5px 0 0;
  font-weight: 800;
  width: auto;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.breadcrumbs li:first-of-type {
  margin-left: 5px;
}
.breadcrumbs li a {
  color: #605c5c;
}
.odbor-top p {
  padding: 0 5px;
}
.blog-text a, .title_content a {
  text-decoration: none;
  color: #c52b2c !important;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  background-color: #f15a2445;
  border-radius: 4px;
  padding: 3px 6px;
  margin: 0 3px;
}
.blog-text a:hover, .title_content a:hover {

  box-shadow: 0 0 0 4px #e23f3f97;
}
.image {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.title_content span a {
  display: block;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
}
.others_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}
.title_content span a {
  display: inline;
}
.raw-html-embed {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.raw-html-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.title_content span a span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  left: calc(50%);
  transform: translateX(-50%);
  bottom: -1px;
  z-index: -1;
  background: #f15b247b;
  transition: all 0.3s ease-in-out;
}
.title_content span a:hover span::before {
  height: 100%;
}
.blog-text a {
  text-decoration: underline;
}
.title_content {
  overflow: visible;
  display: flex;
  flex-direction: column;
  width: 80%;
}
.odbor-header {
  overflow: visible;
  z-index: 0;
  margin: 20px 0px;
  font-size: 50px;
  font-weight: 800;
  text-align: center;
}
.blog-text {
  text-align: left;
}
blockquote {
  margin: 0 !important;
  padding: 20px 20px 20px 80px;
  background-color: #e5e5e5 !important;
  border-radius: 10px;
  word-break: break-all;
  position: relative;
}
blockquote::before {
  font-size: 40px;
  font-weight: 900;
  content: ">";
  top: 10%;
  position: absolute;
  transform: translateX(-50px) !important;
}
blockquote > p {
  font-weight: 800;
  margin: 0;
}
.blog-text li {
  width: 100%;
}
.content_container span {
  background: none !important;
}
strong {
  font-weight: 800;
  color: #c52b2c;
}
u {
  text-decoration-color: #c52b2c;
}
/*tabuľka*/
figure {
  margin: 20px 0;
  overflow-x: auto;
  width: 100%;
}
figcaption {
  box-sizing: border-box;
  font-weight: 800;
  color: #fff;
  padding: 5px 8px;
  text-align: center;
  width: 100%;
  background-color: #f15a24;
  border: 1px solid #c52b2c;
  border-bottom: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
  background: #fff;
  table-layout: fixed;
}
th {
  padding: 5px 8px;
  background: #e2e5e7;
  border: 1px solid #c7c7c7;
  width: auto;
}
td {
  border: 1px solid #c7c7c7;
  padding: 5px 8px;
}
hr {
  width: 100%;
  border: 0.5px solid var(--border-light);
  border-radius: 24px;
  margin: 20px 0;
}
/*koniec tabuľky*/
.more_results {
  padding-right: 0;
  margin: 0;
  font-size: 23px;
  font-weight: 900;
}
.file {
  width: 100%;
}

.file-container {
  border-top: none !important;
  border: 1px solid #ccc;
  border-radius: 0 0 12px 12px;
  margin-top: 0;
}
.h3-files {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 800;
}
.odbor-main_content ul {
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  padding: 0;
}
.line-blog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  stroke-width: 10px;
  stroke: -webkit-linear-gradient(270deg, #f15a24 0%, #c52b2c 100%);
}
.svg-blog {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
}
@media screen and (max-width: 820px) {
  .blog-text a::before {
    display: none;
  }
  .blog-text a::after {
    display: none;
  }
  .main-odbor_container {
    justify-content: center;
    width: 100% !important;
  }
  .odbor-main_content {
    width: 100%;
    padding: 0 2% !important;
    margin-top: calc(90px + 2.5%) !important;
  }

  .sidebar {
    display: none;
  }
  .sidebar_container {
    border: none !important;
  }
  .title_content {
    width: 90% !important;
  }
  blockquote {
    margin-left: 0 !important;
  }
  .others_container {
    width: 90%;
  }
}
