.big-photo-album-container{
	  display: flex;
	  justify-content: center;
	    padding-top: calc(90px + 2.5%) !important;
		flex-direction: column;
		width: 100%;
}
.photo-specific-album-container{
	width: calc(100% - 40px);
		margin: 0 20px;
}
.photo-specific-album-container h2{
	font-size: 2rem;
	margin: 10px 0 10px 12px;
}
.photo-container-album{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border: 1px solid var(--border-light);
	border-radius: 16px;
}

.photoalbum-photo{
		border-radius: 12px;
width: 24%;
margin: 0.5%;
	position: relative;
}
.photoalbum-photo img, .photoalbum-photo video{
	border-radius: 12px;
	aspect-ratio: 1.6 / 1;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.video-badge{
	position: absolute;
	left: 0;
	padding: 5px 10px;
	border-radius: 6px;
	z-index: 2;
	margin: 10px 0 0 10px;
	background-color: white;
	border: 1px solid var(--border-light);
}
.photoalbum-photo p{
	color: var(--text);
	font-weight: 800;
		margin: 0;
}
.photoalbum-photo img:hover, .photoalbum-photo video:hover{
	cursor: pointer;
}
@media screen and (max-width: 1020px){
	.photoalbum-photo{
		width: calc(97% /3);
		margin: 0.5%;
	}
}
@media screen and (max-width: 620px){
	.photoalbum-photo{
		width: 49%;
		margin: 0.5%;
	}
}
@media screen and (max-width: 400px){
	.photoalbum-photo{
		width: 100%;
	}
}