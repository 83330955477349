.loading-screen-finish {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
}
.loading-screen-finish img {
  width: 20rem;
}
/* Badge */
.type-badge {
  padding: 7px 10px;
  display: inline-block;
  font-weight: 800;
  color: #fff;
  vertical-align: baseline;
  border-radius: 12px;
}
.type-badge p {
  margin: 0;
}
/* Badge end */
.apps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  height: 100%;
  margin: 20px;
}
.apps-container h1 {
  font-size: 3rem;
  font-weight: 900;
  margin-top: 10rem;
  text-align: center;
}
.apps-container-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.single-app-container {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: calc(25% - 80px);
  margin: 20px;
  background-color: #fff;
  overflow-y: hidden;
}
@media screen and (max-width: 1020px) {
  .single-app-container {
    width: calc(50% - 80px);
  }
}
@media screen and (max-width: 620px) {
  .single-app-container {
    width: calc(100% - 80px);
  }
}
.h3-apps {
  font-size: 2rem;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--text);
}
.single-app-container > img {
  width: 150px;
  height: 150px;
  margin-bottom: -75px;
}
.card-no-image-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.type-badge {
  align-self: flex-start;
}
.app-more-info {
  background-color: rgb(228, 228, 228);
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
.top-card-info-apps {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* Big Card */
.container-apps-big {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.1);
}
.big-nothing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -4;
}
.app-more-info-big {
  position: absolute;
  align-items: center;
  display: flex;
  border-radius: 24px;
  background-color: #fff;
  width: fit-content;
  padding: 20px;
  height: 80%;
  flex-direction: column;
  width: 80%;
}
.app-more-info-big h3 {
  font-size: 3rem;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}
.app-more-info-big p {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 20px;
  text-align: left;
}
.app-more-info-big .app-more-info {
  align-self: flex-end;
}
.apps-info-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 34.5px - 61px - 40px - 40px);
  align-items: center;
}
.apps-info-text-container {
  align-items: center;
  flex-direction: column;
  position: absolute;
  overflow: scroll;
  width: 100%;
  display: flex;
  height: 100%;
}
.apps-description-container {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: scroll;
  margin-top: 20px;
}
.apps-info-container img {
  height: 80%;
  aspect-ratio: 2/1.6;
  border-radius: 12px;
  object-fit: cover;
  object-position: top;
}
.app-presence-container {
  display: flex;
  flex-direction: row;
}
.apps-manual-numbers-container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: min-content;
  max-width: 80%;
  align-items: center;
}
.apps-info-container ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
}
.apps-info-container li {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-width: 0;
    cursor: pointer;
  list-style: none;
  padding: 8px 20px;
  margin: 3px 5px;
  border-radius: 6px;
}
.manual-numbers {
  font-weight: 800;
}
.app-open-link{
  width: fit-content;
  cursor: pointer;
    padding: 8px 20px;
  margin: 3px 5px;
  border-radius: 6px;
  background-color: #c52b2c;
  font-weight: 700;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.app-open-link:hover{
  box-shadow: 0 0 0 3px #c55f5f89;
}
@media screen and (max-width: 720px) {
  .apps-info-container {
    flex-direction: column;
  }
  .app-more-info-big {
    overflow-y: scroll;
  }
  .app-more-info {
    position: sticky;
    top: 0;
  }
}
