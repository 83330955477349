.share-menu-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.share-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-menu-banner {
  width: 640px;
  background-color: #fff;
  padding: 24px;
  border-radius: 24px;
  z-index: 1000;
  height: 400px;
}
.share-menu-heading {
  font-size: 30px;
  font-weight: 800;
  margin: 10px;
  margin-bottom: 30px;
}
.share-menu-sharer {
  width: calc(100% - 20px);
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 50px;
  border-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
}
