.news-type-novinka{
	background: linear-gradient(270deg, #298f14 0%, #1b5f0d 100%);
	color: white;
	width: min-content;
	font-weight: 800;
padding: 5px 10px;
border-radius: 12px;
}
.news-type-oznam{
	background: linear-gradient(270deg, #F15A24 0%, #C52B2C 100%);
	color: white;
	width: min-content;
	font-weight: 800;
padding: 5px 10px;
border-radius: 12px;
}
.news-type-odbor{
	background: linear-gradient(270deg, #e9b115 0%, #d38302 100%);
	color: white;
	width: min-content;
	font-weight: 800;
padding: 5px 10px;
border-radius: 12px;
}